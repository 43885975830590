import {
  Heading,
  Label,
  StarRating,
  TriggerButton,
  TriggerEvent,
  Link,
  BadgeValue,
} from '@loveholidays/design-system';
import React, { forwardRef, useState } from 'react';

import { getCancellationBadge } from './HolidayCollection';
import { Pricing } from './Pricing';
import { useHolidayCollectionPandaUrl } from './useHolidayCollectionPandaUrl';
import {
  Accommodation,
  AccommodationUrls,
  Image as ImageType,
  Sashes,
  DynamicPackageResult,
  HolidayCollectionProps as HolidayCollectionType,
  ProductTrackingList,
  SearchSelection,
} from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { HotelDetailsModal } from '@Components/HotelDetails/HotelDetailsModal';
import { HotelDetailsTabId, mapSelectedTab } from '@Components/HotelDetails/HotelDetailsTypes';
import { useModal } from '@Components/Modal/useModal';
import { RatingLink } from '@Components/RatingLink/RatingLink';
import { useImageFallbackSrc } from '@Core/hooks/useImageFallbackSrc';
import { HotelDetailsModalFooter } from '@Pages/search-results/hotel-details-modal/HotelDetailsModalFooter';
import { useProductClickTracking } from '@Tracking';
import { Card } from '@UX/components/Card/Card';

export type CollectionHotelFields = Pick<
  Accommodation,
  'id' | 'name' | 'starRating' | 'ratings' | 'locationBreadcrumbs'
> & {
  images: {
    items: ImageType[];
  };
  urls?: AccommodationUrls;
  sashes?: Sashes;
  isFamily?: boolean;
};

export interface CollectionHotelProps extends Omit<ClassNameProps, 'id'>, CollectionHotelFields {
  type: ProductTrackingList;
  linkTarget: HolidayCollectionType['linkTarget'];
  offer?: DynamicPackageResult;
  searchSelection?: SearchSelection;
  internalLink?: boolean;
}

export const HolidayCard = forwardRef<HTMLDivElement, CollectionHotelProps>(
  (
    {
      className,
      id,
      name,
      locationBreadcrumbs,
      ratings,
      starRating,
      images,
      urls,
      type,
      offer,
      sashes,
      linkTarget,
      searchSelection: offerSearchSelection,
      isFamily = false,
      internalLink = true,
    },
    ref,
  ) => {
    const [isHotelDetailsModalOpen, setHotelDetailsModalOpen, setHotelDetailsModalClose] =
      useModal();
    const [tab, setTab] = useState<HotelDetailsTabId>();
    const trackProductClick = useProductClickTracking();
    const imageFallbackSrc = useImageFallbackSrc();

    const cancellationBadge = getCancellationBadge(offer?.badges);
    const refundablePackage = cancellationBadge ? true : undefined;
    const modalOpenHandler = (
      e: TriggerEvent,
      modalOpener: (e: TriggerEvent) => void,
      selectedTab?: HotelDetailsTabId,
    ) => {
      e.stopPropagation();
      trackProductClick([{ masterId: id, name, refundablePackage }], { list: type });
      setTab(selectedTab);
      modalOpener(e);
    };

    const pandaUrl = useHolidayCollectionPandaUrl({
      offer,
      offerSearchSelection,
      masterId: id,
    });

    const href = linkTarget === 'panda' ? pandaUrl : urls?.seo!;

    let sashesElements;

    if (sashes) {
      const adultOrFamilySashes = isFamily ? sashes.family : sashes.adult;
      sashesElements = (adultOrFamilySashes || []).map((sash) => (
        <BadgeValue
          key={sash.label}
          category={sash.variant}
          sx={{
            marginRight: '3xs',
            marginBottom: '3xs',
          }}
        >
          {sash.label}
        </BadgeValue>
      ));
    }

    const bottomSash =
      ratings?.length > 0
        ? ratings.map((rating) => (
            <RatingLink
              key={rating.id}
              provider={rating.provider}
              trackingAction={type}
              reviewCount={rating.ratingCount}
              recommendation={rating.recommendation}
              rating={rating.rating}
              display="recommendation"
              floating
              sx={{ marginTop: 'xs' }}
              onClick={(e) =>
                modalOpenHandler(e, setHotelDetailsModalOpen, mapSelectedTab(rating.provider))
              }
            />
          ))
        : null;

    const showHotelModalFooter = !!offer && pandaUrl;

    const collectionHotelCard = (
      <Card
        variant="Grid"
        data-id={`collectionHotel-card-${linkTarget}`}
        ref={ref}
        fallbackImageSrc={imageFallbackSrc}
        images={images?.items}
        height={175}
        topSashes={sashesElements}
        trackingObject={{ masterId: id, name }}
        sx={{
          height: '100%',
          position: 'relative',
          '&:hover': {
            boxShadow: 'elevationHover',
            color: 'textDefault',
          },
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            <StarRating
              rating={starRating}
              sx={{
                marginBottom: '3xs',
              }}
            />
            <Heading
              as="h3"
              className="hotelTitle"
              variant="smallbold"
              sx={{
                marginBottom: '4xs',
              }}
            >
              {name}
            </Heading>
            <Label
              className="locationBreadcrumbs"
              variant="extrasmall"
              sx={{
                display: 'inline-block',
                color: 'textDimmedmedium',
              }}
            >
              {locationBreadcrumbs.items.map(({ name }) => name).join(', ')}
            </Label>
            <div>{bottomSash}</div>
          </div>
          {!!offer && !!offer.pricing && (
            <Pricing
              sx={{
                marginTop: '2xs',
                display: 'flex',
                flexDirection: 'column',
              }}
              lowestDeposit={offer.lowestDeposit}
              lowestDepositPerPerson={offer.lowestDepositPerPerson}
              pricing={offer.pricing}
              badges={offer.badges}
            />
          )}
        </div>
        {isHotelDetailsModalOpen && (
          <HotelDetailsModal
            onClose={setHotelDetailsModalClose}
            data-id="hotel-details-modal"
            masterId={id}
            selectedTabId={tab}
            showCtas={!showHotelModalFooter}
            trackingAction="hotel-card-hdp-modal"
            Actions={
              showHotelModalFooter ? (
                <HotelDetailsModalFooter
                  accommodation={{
                    id,
                    name,
                  }}
                  actionType={type}
                  offer={offer}
                  pandaUrl={pandaUrl}
                />
              ) : undefined
            }
          />
        )}
      </Card>
    );

    if (linkTarget === 'hdp_modal') {
      return (
        <TriggerButton onTrigger={(e) => modalOpenHandler(e, setHotelDetailsModalOpen)}>
          {collectionHotelCard}
        </TriggerButton>
      );
    }

    return (
      <Link
        internal={internalLink}
        className={className}
        href={href}
        onClick={() =>
          trackProductClick(
            [
              {
                masterId: id,
                name,
                refundablePackage,
              },
            ],
            { list: type },
          )
        }
        sx={{
          height: '100%',
          color: 'textDefault',
          textDecoration: 'none',
          borderRadius: '8',
          '&:visited': {
            color: 'textDefault',
          },
        }}
      >
        {collectionHotelCard}
      </Link>
    );
  },
);
